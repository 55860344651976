import React from "react"
import Layout from "../components/layout"
import bg from "../images/backgrounds/detail-bg.png"
import Row from "../components/row"
import SEO from "../components/seo"
import linkedInImage from "../images/hands-on-workshop-timextender/tx-linkedIn-afbeelding.jpg"
import FormRegistration from "../components/formRegistration"

const registrationPage = () => (
  <Layout bigfoot={false} hideMap={true}>
    <SEO title={"Hands-on workshop: TimeXtender"}
         description={"Bouw zelf je datawarehouse nieuwe stijl; slimmer, sneller enstabieler. TimeXtender biedt integraal inzicht in data van brontot applicatie. Efficiënter en sneller data laden. 10 keer snellertot data inzichten komen!"}/>
    <Row size="small" image={bg} classes="pt-200" id="Registration">
      <h1 className="uppercase t-30 bold mb-20 column col-12">Gratis online demo: TimeXtender</h1>
      <div className="hbox">
        <div className="column col-7">
          <img src={linkedInImage} style={{maxWidth:"480px", width:"100%", marginBottom:"15px"}} alt="Automatiseer uw datawarehouse met TimeXtender"/>
          <p>
            Bouw zelf je datawarehouse nieuwe stijl; slimmer, sneller en stabieler. TimeXtender biedt integraal inzicht
            in data van bron tot applicatie. Efficiënter en sneller data laden. 10 keer sneller tot data inzichten komen!
            <br/>
            Tijdens de online demo van 2h ervaart u hoe een TimeXtender expert aan de slag gaat met TimeXtender.
            We adviseren met 2 of meer personen de demo bij te wonen, bijvoorbeeld een 'business user' en iemand vanuit IT.
            <br/>
          </p>
          <h3 className="bold mt-20 uppercase">Demo tijd:</h3>
          <p>
            9:00 – 11:00
          </p>
          <br />
          <h3 className="bold mt-20 uppercase">DEMO DAGEN:</h3>
          <ul>
             <li>Woensdag 21 april</li>
             <li>Woensdag 2 juni</li>
          </ul>
          <br/>
          <h3 className="bold mt-20 uppercase">Locatie:</h3>
          <p>Microsoft Teams</p>
          <br/>
          <h3 className="bold mt-20 uppercase">Workshop op locatie:</h3>
          <p>Wilt u liever zelf aan de slag? In overleg en rekening houdend met afstandsmaatregelen kunnen we ook een
            uitgebreide hands-on workshop op locatie organiseren. Laat het ons weten als dat uw voorkeur is.
          </p>
        </div>
        <div className="column col-5">
          <FormRegistration/>
        </div>
      </div>
    </Row>
  </Layout>
)

export default registrationPage
