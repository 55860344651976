import text from "../data/nl"
import React, { Component } from "react"
import axios from "axios"
import BarLoader from "react-spinners/BarLoader"

class FormRegistration extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: "",
      name: "",
      company: "",
      emailAddress: "",
      phone: "",
      validations: {
        date: true,
        name: true,
        company: true,
        emailAddress: true,
        phone: true,
      },
      success: null,
      loading: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.dates = ["Woensdag 21 april", "Woensdag 2 juni"]
  }

  render() {
    return (
      <div className="contact-form hbox" id="ContactForm">
        <div className="full-width">
          <div>
            <label className="block bold mb-12" htmlFor="date">Datum *</label>
            <div className={this.state.validations.date ? "valid" : "invalid-radio"}>
              {/* todo refactor to loop */}
              <label className="block">
                <input
                  name="date"
                  value={this.dates[0]}
                  checked={this.state.date === this.dates[0]}
                  onChange={this.handleChange}
                  type="radio"
                />{this.dates[0]}
              </label>
              <label className="block">
              <input
                name="date"
                value={this.dates[1]}
                checked={this.state.date === this.dates[1]}
                onChange={this.handleChange}
                type="radio"
              />{this.dates[1]}
            </label>
            </div>
          </div>
          <div className={this.state.validations.name ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.name}>
              {text.contact.form.name} *
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.name}
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div className={this.state.validations.company ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.company}
            >
              {text.contact.form.company} *
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.company}
              name="company"
              value={this.state.company}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div className={this.state.validations.emailAddress ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.emailAddress}
            >
              {text.contact.form.emailAddress} *
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.emailAddress}
              name="emailAddress"
              value={this.state.emailAddress}
              onChange={this.handleChange}
              type="text"
            />
          </div>
          <div className={this.state.validations.phone ? "valid" : "invalid"}>
            <label
              className="block bold mb-12 mt-20"
              htmlFor={text.contact.form.phone}
            >
              {text.contact.form.phone} *
            </label>
            <input
              className="full-width p-12 borderless"
              id={text.contact.form.phone}
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
              type="text"
            />
          </div>
        </div>
        <div className="full-width">
          <div className="mt-20 pt-12 float-left t-13 lh-115 bold">
            * verplichte velden
          </div>
          <button
            className="button float-right"
            onClick={this.handleSubmit}
          >
            {text.contact.form.submit}
          </button>
        </div>
        <div className={"col-12 column t-20 bold align-center mt-30 h-64 relative"}>
          <div className={"center-vertically-and-horizontally " + (this.state.loading ? "opacity-100" : "opacity-0")}>
            <BarLoader sizeUnit={"px"} size={100} color={"white"} loading={this.state.loading}/>
          </div>
          <span
            className={"transition-opacity " + (this.state.success ? "opacity-100" : "opacity-0")}>Bedankt voor het aanmelden</span>
        </div>
      </div>
    )
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value })
  }

  handleSubmit() {
    let hasErrors = false
    const validations = {
      date: true,
      name: true,
      company: true,
      emailAddress: true,
      phone: true,
    }
    if (this.state.name === "") {
      validations.name = false
      hasErrors = true
    }
    if (this.state.company === "") {
      validations.company = false
      hasErrors = true
    }
    if (this.state.emailAddress === "") {
      validations.emailAddress = false
      hasErrors = true
    }
    if (!/\S+@\S+\.\S+/.test(this.state.emailAddress)) {
      validations.emailAddress = false
      hasErrors = true
    }
    if (this.state.phone === "") {
      validations.phone = false
      hasErrors = true
    }
    if (this.state.date === "") {
      validations.date = false
      hasErrors = true
    }

    if (hasErrors) {
      this.setState(prevState => {
        return {
          ...prevState,
          validations: {
            name: validations.name,
            company: validations.company,
            emailAddress: validations.emailAddress,
            date: validations.date,
            phone: validations.phone,
          },
        }
      })
    } else {

      this.setState(prevState => {
        return {
          ...prevState,
          loading: true,
        }
      })

      const bodyFormData = new FormData()

      bodyFormData.set("name", this.state.name)
      bodyFormData.set("company", this.state.company)
      bodyFormData.set("emailAddress", this.state.emailAddress)
      bodyFormData.set("date", this.state.date)
      bodyFormData.set("phone", this.state.phone)

      axios({
        method: "post",
        url: "https://ddc.bettywebblocks.com/website/registration",
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      }).then(() => {
        this.setState(() => {
          return {
            date: "",
            name: "",
            company: "",
            emailAddress: "",
            phone: "",
            validations: {
              date: true,
              name: true,
              company: true,
              emailAddress: true,
              phone: true,
            },
            success: true,
            loading: false,
          }
        })
        console.log(this.state)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}

export default FormRegistration
